// import React from "react";
// import ReactDOM from "react-dom/client";
// import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
// import axios from 'axios';


// import LoginPage from "./pages/Login";
// import ResetPage from "./pages/Reset";
// import SubsPage from "./pages/Subs";
// import KsPage from "./pages/Ks";
// import ProgressPage from "./pages/Progress/Progress";
// import ProgressOnePage from "./pages/Progress/ProgressOne";
// import MainPage from "./pages/Main";

// import PageNotFound from "./pages/PageNotFound";
// import Menu from "./components/Menu";
// import TopBar from "./components/TopBar";


// import "./assets/css/main.css";
// const restApiHost = useState('123');

// axios.get(`/auth/user/`, {headers: {'Authorization': 'Token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZXhwIjoxNzE3MTQ0MzA0fQ.0l0GJSJxFsVz25MVbeFADOgvqDNjgNZ9C20udJs-GXg'}})
//     .then(function(response){
      
//       console.log(response.data);
//   }).catch(function(err){
//    if (!err?.response) {
//       console.log("No Server Response");
//     } else if (err.response?.status === 403) {
//       console.log(err.response.data.user.detail);
//     }
//   })


// const container = ReactDOM.createRoot(document.getElementById("container"));
// container.render(

//     <BrowserRouter>
//        {restApiHost}
//         <Menu/>
       
//         <div id="page">
//             <TopBar/>
            
//                 <Routes>
//                     <Route path="/login/" element={<LoginPage />} />
//                     <Route path="/reset/*/" element={<ResetPage />} />
//                     <Route path="/subs/*/" element={<SubsPage />} />
//                     <Route path="/ks/*/" element={<KsPage />} />
//                     <Route path="/progress/" element={<ProgressPage />} />
//                     <Route path="/progress/:id/" element={<ProgressOnePage />} />
//                     <Route path='/404' element={<PageNotFound />}/>
//                     <Route path='/' element={<MainPage />}/>

//                     <Route path='*' element={<Navigate replace to="/404" />}/>
//                 </Routes>
             
//         </div>
//     </BrowserRouter>
// );

import React from "react";
import { createRoot } from 'react-dom/client';


import App from "./App";
import "./assets/css/main.css";
// import axios from 'axios';





const container = document.getElementById('container');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App/>);