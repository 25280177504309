import React, {useState} from 'react';

import { Link } from 'react-router-dom';
import { useAuth } from "../../components/AuthProvider";
import axiosInstance from '../../components/AxiosInstance';
import keycloak_small_logo from '../../assets/img/keycloak_small.svg';
import logo_svg from '../../assets/img/logo.svg';
import mail_svg from '../../assets/img/mail.svg';
import pass_svg from '../../assets/img/pass.svg';
import show_svg from '../../assets/img/show.svg';
import hide_svg from '../../assets/img/hide.svg';
import {config} from '../../config/config.js';

function generateRandomString(length=32) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export default function LoginPage() {

  const [showPass , setShowPass] = useState(false)


  const [state , setState] = useState({
        login : "",
        pass : "",
        successMessage: false,
        errorMessage: false
    })

   // const { token } = useAuth();
  // setToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZXhwIjoxNzE3MjY3MTY0fQ.-ZvPxA0XxE-5r026RZeqo98I8JoaN_qIX6c5DKgCQro");
  // setToken(false);
  // console.log(token)
  

  const handleShowPass = (e) => {
    setShowPass(true)
 }
 const handleHidePass = (e) => {
    setShowPass(false)
 }
  const handleKeycloak = (e) => {

    const state=generateRandomString()
    window.sessionStorage.setItem("state", state);

    // window.location.href = 'https://sublk.ru:8443/realms/sublk-realm/protocol/openid-connect/auth?client_id=sublk-client&response_type=code&redirect_uri=http://localhost:8000/keycloak/&scope=profile email openid&state=your_state_info';

    const auth_url=config.keycloak.server_url+'/realms/'+config.keycloak.realm_name+'/protocol/openid-connect/auth?client_id='+config.keycloak.client_id+'&response_type=code&redirect_uri='+config.keycloak.redirect_uri+'&scope=profile email openid&state='+state

    window.location.href = auth_url
        // https://sublk.ru:8443/realms/sublk-realm/protocol/openid-connect/auth?client_id=sublk-client&response_type=code&redirect_uri=http://localhost:8000/keycloak/&scope=profile email openid&state=your_state_info
        


    }

  const handleChange = (e) => {
        const {id , value} = e.target   
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }
    const handleReset= (e) => {
        e.preventDefault();
        if(!state.login) {
            setState(prevState => ({
                        ...prevState,
                        'errorMessage' : "Введите почту в поле ввода:"
                    }))
        } else {
            setState(prevState => ({
                        ...prevState,
                        'successMessage' : 'Успешный вход',
                        'errorMessage' : null
                    }))
           axiosInstance.post('/user/reset/', {'email': state.login})
            .then(function (response) {
                if(response.status === 200){
                    window.location.href = '/reset/';
                    // console.log(response.status)
                }
            })
            .catch(function (error) {
              setState(prevState => ({
                        ...prevState,
                        'successMessage' : null,
                        'errorMessage' : "Ошибка"
                    }))
               

            });
        }
    }
  const handleSubmit = (e) => {

    e.preventDefault();
        const payload={
              "email": state.login,
              "password": state.pass

        }

        axiosInstance.post('/user/login/', payload)
            .then(function (response) {
                if(response.status === 200){
                    setState(prevState => ({
                        ...prevState,
                        'successMessage' : 'Успешный вход',
                        'errorMessage' : null
                    }))
                    // localStorage.setItem(ACCESS_TOKEN_NAME,response.data.token);
                    // redirectToHome();
                    // props.showError(null)
                        localStorage.setItem('access', response.data.access);
                        localStorage.setItem('refresh', response.data.refresh);
                        localStorage.setItem('email', response.data.email);
                        localStorage.setItem('sub_name', response.data.sub_name);
                        localStorage.setItem('keycloak', false);
                    // setUser({"email": response.data.email, "access": response.data.access, "refresh": response.data.refresh});
                     // console.log(response.data);
                     // return (<Navigate to="/progress/" replace={true} />)
                     // navigate('/progress/')
                     window.location.href = '/estimates/';
                }
            })
            .catch(function (error) {
              setState(prevState => ({
                        ...prevState,
                        'errorMessage' : "Неверная почта или пароль"
                    }))
               

            });
   /*       const payload={"user": {
                "email": "sub@test.com",
                "password": "12345678"
            }
        } */

        
        /*e.preventDefault();
        if(state.password === state.confirmPassword) {
            sendDetailsToServer()    
        } else {
            props.showError('Passwords do not match');
        }*/
    }

  // const redirectToHome = () => {

  //       props.history.push('/home');
  //   }
  //   const redirectToRegister = () => {
  //       props.history.push('/reset'); 
  //       props.updateTitle('Reset');
  //   }

  return (
   
      <div className="login_block">
        <div className="login_header"><img src={logo_svg} alt=""/>Вход</div>
         
        {state.successMessage?
             <div className="loader"><div className="loader_row"><div></div><div></div><div></div><div></div></div></div>  
        :
            <>
            <div className="value"><button onClick={handleKeycloak}><img src={keycloak_small_logo} alt=""/>Войти через KeyCloak</button></div>

            <div className="login_bottom"><div>или войти через</div></div>

            
            <div className="error" style={{display: state.errorMessage ? 'block' : 'none' }}>
                    {state.errorMessage}
            </div>
             <form onSubmit={handleSubmit}>
                <div className="value"><img src={mail_svg} className="icon" alt=""/><input type="email" id="login" placeholder="Введите электронную почту" defaultValue={state.login} onChange={handleChange}/></div>
                <div className="value"><img src={pass_svg} className="icon" alt=""/><img src={show_svg} className={showPass?"pass pass_show":"pass active pass_show"} onClick={handleShowPass} alt=""/><img src={hide_svg} className={showPass?"pass active pass_show":"pass pass_show"} onClick={handleHidePass} alt=""/><input type={showPass?'text':'password'} id="pass" placeholder="Введите пароль" defaultValue={state.pass} onChange={handleChange} /></div> 
                <div className="submit"><input type="submit" value="Войти"/></div>
                <div className="value">Забыли пароль? <Link className="reset" onClick={handleReset}>Восстановить</Link></div>
            </form>
            </>
            
        }
        
        
        
      </div>
    
  );
}