import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axiosInstance from '../../components/AxiosInstance';
import {config} from '../../config/config.js';
import { useAuth } from "../../components/AuthProvider";
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";
import header_progress_svg from '../../assets/img/header_progress.svg';
import filter_svg from '../../assets/img/filter.svg';
import arrow_down_svg from '../../assets/img/arrow_down.svg';
import ReactPaginate from 'react-paginate';
// axios.get("/base/deals/",
//   {headers: {'Authorization': 'Token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZXhwIjoxNzE2ODc0OTg0fQ.PybPZuY9Cf2Rn6leZHDfsI5Mt3N93qt3gqnsuUSmAZM'}})
// .then((res) => console.log(res.data))
//       .catch((err) => console.log(err));

// getEvents(){
//         axios
//             .get("http://127.0.0.1:8000/api/getevents")
//             .then(response =>{
//                 this.state2.events = response.data;
//             }) 
//             .catch(err => console.log(err));
//     };


export default function ReportsPage() {

  const navigate = useNavigate();

   const [reports, setReports] = useState([]);
   const [isLoading, setisLoading]=useState(true);
   const [isError, setisError] = useState(false);

  useEffect(() => {
    setisLoading(true);
// console.log('token', axios.defaults.headers.common["Authorization"])
   axiosInstance.get("/base/reports/")
    .then(function(response){
      


      setReports(response.data)
      setisLoading(false)
    })
    .catch((err) => {
      if(err.response.status===403) {
        // setToken(false)
      }
        setisError(true);
        setisLoading(false);
      });
  }, [])



const handlePageClick = (e) => {
   
    setisLoading(true)

   axiosInstance.get("/base/reports/?page="+(e.selected+1))
    .then(function(response){
      


      setReports(response.data)
      setisLoading(false)
    })
    .catch((err) => {
      if(err.response.status===403) {
        // setToken(false)
      }
        setisError(true);
        setisLoading(false);
      });
  }
  // const [post, setPost] = React.useState(null);

  // React.useEffect(() => {
  //   axios.get("/base/deals/", {headers: {'Authorization': 'Token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZXhwIjoxNzE2ODc0OTg0fQ.PybPZuY9Cf2Rn6leZHDfsI5Mt3N93qt3gqnsuUSmAZM'}}).then((response) => {
  //     setPost(response.data);
  //     console.log(response.data)
  //   });
  // }, []);

/*
<div className="block" key={deal.id} onClick={() => {window.location.href=`/progress/${deal.id}/`}}>
        <div className="row">
            <div className="name">{deal.name}<div className="date">от {deal.date}</div></div>
            
            <div className="info">
                <div className="organization">{deal.object}</div>
                <div className="sub">{deal.project}</div>
                <div className="object">{deal.state}</div>
                
            </div>
            <div className="sum">
           
              {Object.keys(deal.total).length && Object.keys(deal.total).map((currency, index) => ( 
                <div key={index}>{currency}: {deal.total[currency]}</div>
              ))}
            
            </div>

        </div>
        </div>*/
  

  return (  
    <>
    <Helmet>
            <title>Выполнение</title>
    </Helmet>
    <Menu/>
    <div id="page">
    <TopBar/>
    <div className="container">
      <div className="header">Выполнение</div>
    
      
        <div className="block">
          

          <div className="table nh">
              <div className="table_header">
                <div className="table_cell"  style={{width:'250px'}}>
                  Номер
                </div>
                 <div className="table_cell">
                  Период
                </div>
                <div className="table_cell">
                  Договор
                </div>
                <div className="table_cell">
                  ДС
                </div>
                <div className="table_cell">
                  Смета
                </div>
                 <div className="table_cell">
                  КС-2
                </div>
                <div className="table_cell">
                  Статус
                </div>
              </div>
              {isLoading? 
                <>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div><div className="table_cell"><div className="text_placeholder"></div></div></div>
                 </>
              :
                <>
                {reports.count>0?
                <>
                {reports.reports.map((report) => (
      
                <div className="table_row" key={report.id}>
                    <div className="table_cell" report_id={report.id}>
                         <Link to={`/report/${report.id}/`}>
                          <div class="link">{report.id}</div>
                          <div class="small_info">{new Date(report.date).toLocaleString()}</div>
                        </Link>
                      </div>
                      <div className="table_cell">
                        {new Date(report.date_from).toLocaleDateString()} - {new Date(report.date_to).toLocaleDateString()}
                      </div>
                      <div className="table_cell">
                        <Link to={`/deal/${report.deal_id}/`}>
                          <div class="link">{report.deal_name}</div>
                          <div class="small_info">{report.deal_date}</div>
                        </Link>
                      </div>
                      <div className="table_cell">
                        {report.agreement_id?
                        <Link to={`/agreement/${report.agreement_id}/`}>
                          <div class="link">{report.agreement_name}</div>
                          <div class="small_info">{report.agreement_date}</div>
                        </Link>
                        :null}
                      </div>
                      <div className="table_cell">
                        <Link to={`/estimate/${report.estimate_id}/`}>
                          <div class="link">{report.estimate_number}</div>
                          <div class="small_info">{report.estimate_date}</div>
                        </Link>
                      </div>
                      <div className="table_cell">
                        {report.act_id?
                        <Link to={`/act/${report.act_id}/`}>
                          <div class="link">{report.act_number}</div>
                          <div class="small_info">{new Date(report.act_date).toLocaleDateString()}</div>
                        </Link>
                        :null}
                      </div>
                      <div className="table_cell">
                        {report.back?<div class="back">Возвращён на доработку</div>:null}
                        {report.accept?
                          <>
                            {report.act_id?
                            <div class="accept">Сформирован КС-2</div>
                            :
                            <div class="accept">Принят</div>
                          }
                          </>
                        :null}
                        {report.reject?<div class="reject">Отклонён</div>:null}
                        {!report.back&&!report.accept&&!report.reject?<div className="wait">Отправлен на рассмотрение</div>:null}
                        {report.comment&&(report.reject||report.back)?<div class="comment">{report.comment}</div>:null}
                        
                      </div>

                  </div>

              ))}
                </>
                  :
                  <div className="table_row"><div className="table_cell">Отчёты отсутствуют</div></div>
                }
                </>
              }
           </div>
        </div>
    
    {!isLoading && reports.page_count>1? 
        <ReactPaginate
        breakLabel="..."
        nextLabel="Вперед"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        forcePage={reports.page-1}
        pageCount={reports.page_count}
        previousLabel="Назад"
        renderOnZeroPageCount={null}
      /> :null}
    </div>
    </div>
    </>
  );

}

