
import React, { createContext, useContext, useState } from 'react';
import { Navigate, Outlet, useNavigate } from "react-router-dom";

const AuthContext = createContext(null);
export const useAuth = () => useContext(AuthContext);

export const  AuthProvider = ({ children }) => {

  const [user, setUser] = useState({"email":localStorage.getItem("email"), "sub_name":localStorage.getItem("sub_name"), "access":localStorage.getItem("access"), "refresh":localStorage.getItem("refresh"), "keycloak":JSON.parse(localStorage.getItem("keycloak")), "superadmin":JSON.parse(localStorage.getItem("superadmin")), "admin_name":localStorage.getItem("admin_name")});
  // console.log(axios.defaults.headers.common["Authorization"], 'Token '+token);
   // {headers: {'Authorization': 'Token '+token}})

  // if (token!==null) {
  //     axios.defaults.headers.common["Authorization"] = "Token " + token;
  //     localStorage.setItem('token',token);
  //   } else {
  //     delete axios.defaults.headers.common["Authorization"];
  //     localStorage.removeItem('token')
  //   }

// useEffect(() => {

//     if (user.access) {
//       axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + user.access;
//       localStorage.setItem('access',user.access);
//       localStorage.setItem('refresh',user.refresh);
//       localStorage.setItem('email',user.email);
//     } else {
//       delete axiosInstance.defaults.headers.common["Authorization"];
//       localStorage.removeItem('access')
//       localStorage.removeItem('refresh')
//       localStorage.removeItem('email')
//     }

//   }, [user.access]);
    


// console.log('token3', token, axios.defaults.headers.common["Authorization"]);
//   useEffect(() => {
    
//    console.log('token2', token);
//     if (token!==null) {
//       axios.defaults.headers.common["Authorization"] = "Token " + token;
//       localStorage.setItem('token',token);
//     } else {
//       // delete axios.defaults.headers.common["Authorization"];
//       // localStorage.removeItem('token')
//     }
//   }, [token]);
  // useEffect(() => {

  //     if (token) {
  //       axios.defaults.headers.common["Authorization"] = "Token " + token;
  //       localStorage.setItem('token',token);
  //     } else {
  //       delete axios.defaults.headers.common["Authorization"];
  //       localStorage.removeItem('token')
  //     }
  //   }, [token]);

  // const contextValue = useMemo(
  //   () => ({
  //     token,
  //     setToken,
  //   }),
  //   [token]
  // );

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const RequireAuth = () => {
  
  const { user, setUser } = useAuth();

  // useEffect(() => {

  // console.log('def', axiosInstance.defaults.headers.common["Authorization"])
/*
  if (user.access && !localStorage.getItem("access")) {
      axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${user.access}`
      // axiosInstance.defaults.headers.common["AuthorizationRefresh"] = `Bearer ${user.refresh}`
      // axiosInstance.defaults.headers.common["Keycloak"] = true
      localStorage.setItem('access',user.access);
      localStorage.setItem('refresh',user.refresh);
      localStorage.setItem('email',user.email);
      // console.log('token', axios.defaults.headers.common["Authorization"])
      // axiosInstance.get(`/auth/user/`)
      //   .then(function(response){
          
      //     console.log(response.data);
      // }).catch(function(err){
      //  if (!err?.response) {
      //     console.log("No Server Response");
      //   } else if (err.response?.status === 403) {
      //     console.log(err.response.data.user.detail);
      //     delete axiosInstance.defaults.headers.common["Authorization"];
      //     localStorage.removeItem('token')
      //     localStorage.removeItem('email')
      //      navigate('/login/')
      //   }
      // })
    } else {
      delete axiosInstance.defaults.headers.common["Authorization"];
      // delete axiosInstance.defaults.headers.common["AuthorizationRefresh"];
      localStorage.removeItem('access')
      localStorage.removeItem('refresh');
      localStorage.removeItem('email');
      return <Navigate to="/login" />;
    }*/


    // }) ;
  // Check if the user is authenticated

// console.log(token)
// setToken("123");

  if (!user.access){
    // If not authenticated, redirect to the login page
    return <Navigate to="/login" />;
  }

  // If authenticated, render the child routes
  return <Outlet />;
  // const location = useLocation();
  // console.log(token, location);
  // if (!token) {
  //   return (
  //     <Navigate
  //       to={{ pathname: "/login", state: { from: location } }}
  //       replace
  //     />
  //   );
  // }
  // return <Outlet />;
};

