import React, { useEffect, useState } from 'react';
import axiosInstance from '../../components/AxiosInstance';
import { Link, Outlet, useNavigate, useSearchParams} from 'react-router-dom';
import {config} from '../../config/config.js';
import { useAuth } from "../../components/AuthProvider";
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";
import header_progress_svg from '../../assets/img/header_progress.svg';
import filter_svg from '../../assets/img/filter.svg';
import arrow_down_svg from '../../assets/img/arrow_down.svg';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';



export default function ProgressPage() {

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
   const [acts, setActs] = useState([]);
   const [isLoading, setisLoading]=useState(true);
   const [isError, setisError] = useState(false);

  useEffect(() => {

    setisLoading(true);
// console.log('token', axios.defaults.headers.common["Authorization"])

    let url="/base/admin/acts/"
    if(parseInt(searchParams.get("page"))>1)
      url="/base/admin/acts/?page="+parseInt(searchParams.get("page"))
   axiosInstance.get(url)
    .then(function(response){

      setActs(response.data)
      setisLoading(false)
    })
    .catch((err) => {
        setisError(true);
        setisLoading(false);
      });
  }, [])


const handlePageClick = (e) => {
   
    setisLoading(true)
    navigate("/admin/acts/?page="+(e.selected+1))
   axiosInstance.get("/base/admin/acts/?page="+(e.selected+1))
    .then(function(response){

      setActs(response.data)
      setisLoading(false)
    })
    .catch((err) => {
        setisError(true);
        setisLoading(false);
      });
  }

  return (  
    <>
    <Helmet>
            <title>Список КС-2</title>
    </Helmet>
    <Menu/>
    <div id="page">
    <TopBar/>
    <div className="container">
      <div className="header">КС-2</div>
      <div id="progress">
      
        <div className="block">
          

          <div className="table nh">
              <div className="table_header">
                <div className="table_cell" style={{width:'250px'}}>
                  Номер акта
                </div>
                <div className="table_cell">
                  Договор
                </div>
                <div className="table_cell">
                  ДС
                </div>
                <div className="table_cell">
                  Смета
                </div>
                <div className="table_cell table_right table_cost">
                  Стоимость материалов
                </div>
                <div className="table_cell table_right table_cost">
                  Цена работ
                </div>
                <div className="table_cell table_right table_cost">
                  Итого
                </div>
                <div className="table_cell">
                  Статус
                </div>
              </div>

              {isLoading? 
                <>
                {[...Array(10).keys()].map((load) => (
                <div key={load} className="table_row">
                  {[...Array(8).keys()].map((l) => (<div key={l} className="table_cell"><div className="text_placeholder"></div></div>))}
                  </div>
                ))}
                 </>
              :
                <>
                {acts.count>0?
                <>
                  {acts.acts.map((act) => ( 
        
                  <div className="table_row" key={act.id}>
                      <div className="table_cell" act_id={act.id}>
                         <Link to={`/admin/act/${act.id}/`}>
                          <div class="link">{act.number}</div>
                          <div class="small_info">{new Date(act.date).toLocaleDateString()}</div>
                        </Link>
                      </div>
                      <div className="table_cell">
                        <Link to={`/admin/deal/${act.deal_id}/`}>
                          <div class="link">{act.deal_name}</div>
                          <div class="small_info">{new Date(act.deal_date).toLocaleDateString()}</div>
                        </Link>
                      </div>
                      <div className="table_cell">
                        {act.agreement_id?
                        <Link to={`/admin/agreement/${act.agreement_id}/`}>
                          <div class="link">{act.agreement_name}</div>
                          <div class="small_info">{new Date(act.agreement_date).toLocaleDateString()}</div>
                        </Link>
                        :null}
                      </div>
                      <div className="table_cell">
                        <Link to={`/admin/estimate/${act.estimate_id}/`}>
                          <div class="link">{act.estimate_number}</div>
                          <div class="small_info">{new Date(act.estimate_date).toLocaleDateString()}</div>
                        </Link>
                      </div>
                      <div className="table_cell table_right">
                        {act.money.length && act.money.map((money) => (
                            <div>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(money.cost_materials)} {config.currency_code[money.currency].code}</div>
                          ))}
                      </div>
                      <div className="table_cell table_right">
                        {act.money.length && act.money.map((money) => (
                            <div>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(money.cost_installation)} {config.currency_code[money.currency].code}</div>
                          ))}
                      </div>
                      <div className="table_cell table_right">
                        {act.money.length && act.money.map((money) => (
                            <div>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(money.cost_total)} {config.currency_code[money.currency].code}</div>
                          ))}
                      </div>
                      <div className="table_cell">
                        {act.conducted?<div class="accept">Проведён</div>:<div class="reject">Не проведён</div>}
                      </div>
                    </div>

                  ))}
                  </>
                  :
                  <div className="table_row"><div className="table_cell">Акты отсутствуют</div></div>
                }
                </>
              }
              
           </div>
        </div>
        
        {!isLoading && acts.page_count>1? 
        <ReactPaginate
        breakLabel="..."
        nextLabel="Вперед"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        forcePage={acts.page-1}
        pageCount={acts.page_count}
        previousLabel="Назад"
        renderOnZeroPageCount={null}
      /> :null}
      
      </div>
    </div>
    </div>
    </>
  );

}

