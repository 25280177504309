import React, {useState, useEffect} from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAuth } from "../../components/AuthProvider";

import axios from 'axios'
import {config} from '../../config/config.js';

export default function KeycloakPage() {

    const [queryParameters] = useSearchParams()
    const [error, setError]=useState('');

    
    useEffect(() => {
        const state=sessionStorage.getItem("state")
        if( state && queryParameters.get("state")==state) {
          //   try {
          //   const response = axios.post('/keycloak/', {
          //     'redirect_uri': 'redirect_uri',
          //     'code', 'code'
          //   });

          //   } catch (refreshError) {
          //   // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
          //   console.error('Token refresh failed:', refreshError);
          //   return Promise.reject(refreshError);
          // }
            axios.post("/api/keycloak/", {
              'redirect_uri': config.keycloak.redirect_uri,
              'code': queryParameters.get("code")
            })
            .then(function (response) {
                if(response.status === 200){
                    
                 
                    localStorage.setItem('access', response.data.access);
                    localStorage.setItem('refresh', response.data.refresh);
                    localStorage.setItem('email', response.data.email);
                    localStorage.setItem('keycloak', true);
                    if(response.data.superadmin)
                        localStorage.setItem('superadmin', response.data.superadmin);

                     // console.log(response.data);
                     // return (<Navigate to="/progress/" replace={true} />)
                    window.location.href = '/admin/subs/';
                }
            })
            .catch(function (error) {
              
                setError(error.data)

            });

        } else {
            setError('error state')
            sessionStorage.removeItem("state")
        }

        
    })
    
  // if(window.sessionStorage.getItem("state") && queryParameters.get("state")==window.sessionStorage.getItem("state")) {
  //   setError('s state')

  // } else {
  //   setError('s state')
  // }
  return (
    <>  
        keycloak callback {error}
    </>
  );
}