import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axiosInstance from '../../components/AxiosInstance';
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";

// axios.get("/base/deals/",
//   {headers: {'Authorization': 'Token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZXhwIjoxNzE2ODc0OTg0fQ.PybPZuY9Cf2Rn6leZHDfsI5Mt3N93qt3gqnsuUSmAZM'}})
// .then((res) => console.log(res.data))
//       .catch((err) => console.log(err));

// getEvents(){
//         axios
//             .get("http://127.0.0.1:8000/api/getevents")
//             .then(response =>{
//                 this.state2.events = response.data;
//             }) 
//             .catch(err => console.log(err));
//     };



export default function ProgressPage() {
  const { id } = useParams()


   const [deals, setDeals] = useState([]);
   const [isLoading, setisLoading]=useState(true);
   const [isError, setisError] = useState(false);

  useEffect(() => {
    setisLoading(true);
// console.log('token', axios.defaults.headers.common["Authorization"])
   axiosInstance.get(`/base/admin/deals/${id}`)
    .then(function(response){
      
      response.data.deals.forEach(function(deal) {
        var money_array={}
          deal.estimates.forEach(function(estimate) {
            estimate.money.forEach(function(m) {
              if (m.currency in money_array)
                money_array[m.currency]=m.cost_total+money_array[m.currency]
              else
                 money_array[m.currency]=m.cost_total
          });
        });
        deal['total']=money_array
      });

      setDeals(response.data)
      setisLoading(false)
    })
    .catch((err) => {
      if(err.response.status===403) {
        // setToken(false)
      }
        setisError(true);
        setisLoading(false);
      });
  }, [])

 

  // const [post, setPost] = React.useState(null);

  // React.useEffect(() => {
  //   axios.get("/base/deals/", {headers: {'Authorization': 'Token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZXhwIjoxNzE2ODc0OTg0fQ.PybPZuY9Cf2Rn6leZHDfsI5Mt3N93qt3gqnsuUSmAZM'}}).then((response) => {
  //     setPost(response.data);
  //     console.log(response.data)
  //   });
  // }, []);

/*
<div className="block" key={deal.id} onClick={() => {window.location.href=`/progress/${deal.id}/`}}>
        <div className="row">
            <div className="name">{deal.name}<div className="date">от {deal.date}</div></div>
            
            <div className="info">
                <div className="organization">{deal.object}</div>
                <div className="sub">{deal.project}</div>
                <div className="object">{deal.state}</div>
                
            </div>
            <div className="sum">
           
              {Object.keys(deal.total).length && Object.keys(deal.total).map((currency, index) => ( 
                <div key={index}>{currency}: {deal.total[currency]}</div>
              ))}
            
            </div>

        </div>
        </div>*/
  

  return (  
    <>
    <Helmet>
            <title>Договоры</title>
    </Helmet>
    <Menu/>
    <div id="page">
    <TopBar/>
    <div className="container">
      <div className="header">{deals.sub_name}</div>
      <div id="progress">
      
        <div className="block">
          <div className="block_header">Список договоров</div>

          <div className="table">
              <div className="table_header">
                <div className="table_cell">
                  Договор
                </div>
                <div className="table_cell">
                  Проект, Объект
                </div>

              </div>

          {deals.count>0?
          <>
           {deals.deals.map((deal) => ( 
    
            <div className="table_row" key={deal.id}>
                <div className="table_cell">
                   <Link to={`/admin/deal/${deal.id}/`}>
                    <div class="link">{deal.name}</div>
                    <div class="small_info">{new Date(deal.date).toLocaleDateString()}</div>
                  </Link>
                </div>
                <div className="table_cell">
                  <Link to={`/admin/project/${deal.project_id}/`}>
                    <div class="link">{deal.project}</div>
                  </Link>
                  <Link to={`/admin/object/${deal.object_id}/`}>
                    <div class="link">{deal.object}</div>
                  </Link>
                </div>


              </div>

          ))}
           </>:
                  <div className="table_row"><div className="table_cell">Договоры отсутствуют</div></div>
                }
           </div>
        </div>
    
      </div>
    </div>
    </div>
    </>
  );

}

