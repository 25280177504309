import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axiosInstance from '../../components/AxiosInstance';
import {config} from '../../config/config.js';
import { useAuth } from "../../components/AuthProvider";
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";


export default function ProjectsPage() {

    const navigate = useNavigate();

   const [projects, setProjects] = useState([]);
   const [isLoading, setisLoading]=useState(true);
   const [isError, setisError] = useState(false);

  useEffect(() => {
    setisLoading(true);
// console.log('token', axios.defaults.headers.common["Authorization"])
   axiosInstance.get("/base/projects/")
    .then(function(response){
      


      setProjects(response.data)
      setisLoading(false)
    })
    .catch((err) => {
      if(err.response.status===403) {
        // setToken(false)
      }
        setisError(true);
        setisLoading(false);
      });
  }, [])



  return (  
   <>
    <Helmet>
            <title>Проекты</title>
    </Helmet>
    <Menu/>
    <div id="page">
    <TopBar/>
    <div className="container">
      <div className="header">Проекты</div>
      <div id="progress">
      
        <div className="block">
          

          <div className="table nh">
              <div className="table_header">
                <div className="table_cell">
                  Название
                </div>
                <div className="table_cell">
                  Количество договоров
                </div>
                 
              </div>
              {isLoading? 
                <>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div></div>
                 </>
              :
                <>
                {projects.count>0?
                <>
                {projects.projects.map((project) => (
      
                <div className="table_row" key={project.id}>
                    <div className="table_cell" report_id={project.id}>
                         <Link to={`/project/${project.id}/`}>
                          <div class="link">{project.name}</div>
                         
                        </Link>
                      </div>
                    <div className="table_cell">
                      {project.deals}
                    </div>
                     

                  </div>

              ))}
                </>
                  :
                  <div className="table_row"><div className="table_cell">Проекты отсутствуют</div></div>
                }
                </>
              }
           </div>
        </div>
    
      </div>
    </div>
    </div>
    </>
  );

}

