export const config=
  {
    "domain": "cw.pridex.ru",
    "currency": ['RUB', 'USD', 'EUR'],
    "currency_code": {
      'RUB':{'code':'₽'},
      'USD':{'code':'$'},
      'EUR':{'code':'€'}
    },
    "keycloak": {
      server_url: "https://id.pridex.ru",
      client_id: "cwportal",
      realm_name: "pridex",
      client_secret_key: "lEv37ZgIR5MZJgCIfnr0vdkt7TTWgfg1",
      redirect_uri: "https://cw.pridex.ru/keycloak/"
    }
  }
;
