import React from 'react';
import { Helmet } from 'react-helmet';
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";

export default function PageNotFound() {
  return (
    <>
      <Helmet>
            <title>Страница не найдена</title>
            <meta name='errorpage' content='true' />
            <meta name='errortype' content='404 - Not Found' />
            <meta name='prerender-status-code' content='404' />
      </Helmet>

      <Menu/>
      <div id="page">
      <TopBar/>
        <div className="container">
        
        
        <div className="header">Ошибка 404</div>
        <div>Страница не найдена</div>
      </div>
    </div>
    </>
  );
}