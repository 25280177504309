import React, { useEffect, useState } from 'react';
import axiosInstance from '../../components/AxiosInstance';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import {config} from '../../config/config.js';
import { useAuth } from "../../components/AuthProvider";
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";
import header_progress_svg from '../../assets/img/header_progress.svg';
import filter_svg from '../../assets/img/filter.svg';
import arrow_down_svg from '../../assets/img/arrow_down.svg';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';



export default function ProgressPage() {

  const navigate = useNavigate();

   const [estimates, setEstimates] = useState([]);
   const [isLoading, setisLoading]=useState(true);
   const [isError, setisError] = useState(false);

  useEffect(() => {
    setisLoading(true);
// console.log('token', axios.defaults.headers.common["Authorization"])
   axiosInstance.get("/base/admin/estimates/")
    .then(function(response){

      setEstimates(response.data)
      setisLoading(false)
    })
    .catch((err) => {
        setisError(true);
        setisLoading(false);
      });
  }, [])

  const handleEstimate = (e) => {
       navigate(`/admin/estimate/${e.currentTarget.getAttribute('estimate_id')}/`);
    }

const handlePageClick = (e) => {
   
    setisLoading(true)

   axiosInstance.get("/base/admin/estimates/?page="+(e.selected+1))
    .then(function(response){

      setEstimates(response.data)
      setisLoading(false)
    })
    .catch((err) => {
        setisError(true);
        setisLoading(false);
      });
  }

  return (  
    <>
     <Helmet>
            <title>Список накопительных</title>
    </Helmet>
    <Menu/>
    <div id="page">
    <TopBar/>
    <div className="container">
      <div className="header">Накопительные</div>
        <div className="block">
          

          <div className="table nh">
              <div className="table_header">
                <div className="table_cell" style={{width:'250px'}}>
                  Номер сметы
                </div>

                 <div className="table_cell" style={{width:'250px'}}>
                  Субподрядчик
                </div>
                <div className="table_cell">
                  Договор
                </div>
                <div className="table_cell">
                  ДС
                </div>
                <div className="table_cell">
                  КС-2
                </div>
                <div className="table_cell">
                  Отчёты
                </div>
               
              </div>
              {isLoading? 
                <>
                 {[...Array(10).keys()].map((load) => (
                  <div key={load} className="table_row">
                    {[...Array(6).keys()].map((l) => (<div key={l} className="table_cell"><div className="text_placeholder"></div></div>))}
                    </div>
                  ))}
                 </>
              :
                <>
                {estimates.count>0?
                <>
                {estimates.count>0 && estimates.estimates.map((estimate) => ( 
      
                <div className="table_row" key={estimate.id}>
                    <div className="table_cell">
                      <Link to={`/admin/sum/${estimate.id}/`}>
                        <div class="link">{estimate.number}</div>
                        <div class="small_info">{new Date(estimate.date).toLocaleDateString()}</div>
                      </Link>
                    </div>
                    <div className="table_cell">
                      <Link to={`/admin/sub/${estimate.sub_id}/`}>
                        <div class="link">{estimate.sub_name}</div>
                        <div class="small_info">{estimate.sub_inn}</div>
                      </Link>
                    </div>
                    <div className="table_cell">
                      <Link to={`/admin/deal/${estimate.deal_id}/`}>
                        <div class="link">{estimate.deal_name}</div>
                        <div class="small_info">{new Date(estimate.deal_date).toLocaleDateString()}</div>
                      </Link>
                    </div>
                    <div className="table_cell">
                      {estimate.agreement_id?
                      <>
                        <div class="link">{estimate.agreement_name}</div>
                        <div class="small_info">{new Date(estimate.agreement_date).toLocaleDateString()}</div>
                        </>
                      :null}
                    </div>
                    <div className="table_cell">
                      {estimate.acts && estimate.acts.map((act) => (
                        <div><Link to={`/admin/act/${act.id}/`}>{act.number}<div className="small_right"> от {new Date(act.date).toLocaleDateString()}</div></Link></div>
                      ))}
                    </div>
                    <div className="table_cell">
                      {estimate.reports && estimate.reports.map((report) => (
                        <div><Link to={`/admin/report/${report.id}/`}>{report.number}<div className="small_right"> от {new Date(report.date).toLocaleDateString()}</div></Link></div>
                      ))}
                    </div>
                     
                  </div>

              ))}
                </>
                  :
                  <div className="table_row"><div className="table_cell">Накопительные отсутствуют</div></div>
                }
                </>
              }
           </div>
        </div>
        {!isLoading && estimates.page_count>1? 
        <ReactPaginate
        breakLabel="..."
        nextLabel="Вперед"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        forcePage={estimates.page-1}
        pageCount={estimates.page_count}
        previousLabel="Назад"
        renderOnZeroPageCount={null}
      /> :null}
      </div>

    </div>
    </>
  );

}

