import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axiosInstance from '../../components/AxiosInstance';
import {config} from '../../config/config.js';
import { useAuth } from "../../components/AuthProvider";
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";
import header_progress_svg from '../../assets/img/header_progress.svg';
import filter_svg from '../../assets/img/filter.svg';
import arrow_down_svg from '../../assets/img/arrow_down.svg';
import ReactPaginate from 'react-paginate';

// axios.get("/base/deals/",
//   {headers: {'Authorization': 'Token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZXhwIjoxNzE2ODc0OTg0fQ.PybPZuY9Cf2Rn6leZHDfsI5Mt3N93qt3gqnsuUSmAZM'}})
// .then((res) => console.log(res.data))
//       .catch((err) => console.log(err));

// getEvents(){
//         axios
//             .get("http://127.0.0.1:8000/api/getevents")
//             .then(response =>{
//                 this.state2.events = response.data;
//             }) 
//             .catch(err => console.log(err));
//     };





export default function DealsPage() {

  const navigate = useNavigate();

   const [deals, setDeals] = useState([]);
   const [isLoading, setisLoading]=useState(true);
   const [isError, setisError] = useState(false);

  useEffect(() => {
    setisLoading(true);
// console.log('token', axios.defaults.headers.common["Authorization"])
   axiosInstance.get("/base/deals/")
    .then(function(response){
      
      response.data.deals.forEach(function(deal) {
        var money_array={}
          deal.estimates.forEach(function(estimate) {
            estimate.money.forEach(function(m) {
              if (m.currency in money_array)
                money_array[m.currency]=m.cost_total+money_array[m.currency]
              else
                 money_array[m.currency]=m.cost_total
          });
        });
        deal['total']=money_array
      });

      setDeals(response.data)
      setisLoading(false)
    })
    .catch((err) => {
      if(err.response.status===403) {
        // setToken(false)
      }
        setisError(true);
        setisLoading(false);
      });
  }, [])

 
  const handlePageClick = (e) => {
   
    setisLoading(true)

    axiosInstance.get("/base/deals/?page="+(e.selected+1))
   .then(function(response){
      
      response.data.deals.forEach(function(deal) {
        var money_array={}
          deal.estimates.forEach(function(estimate) {
            estimate.money.forEach(function(m) {
              if (m.currency in money_array)
                money_array[m.currency]=m.cost_total+money_array[m.currency]
              else
                 money_array[m.currency]=m.cost_total
          });
        });
        deal['total']=money_array
      });

      setDeals(response.data)
      setisLoading(false)
    })
    .catch((err) => {
      if(err.response.status===403) {
        // setToken(false)
      }
        setisError(true);
        setisLoading(false);
      });
  }

  // const [post, setPost] = React.useState(null);

  // React.useEffect(() => {
  //   axios.get("/base/deals/", {headers: {'Authorization': 'Token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZXhwIjoxNzE2ODc0OTg0fQ.PybPZuY9Cf2Rn6leZHDfsI5Mt3N93qt3gqnsuUSmAZM'}}).then((response) => {
  //     setPost(response.data);
  //     console.log(response.data)
  //   });
  // }, []);

/*
<div className="block" key={deal.id} onClick={() => {window.location.href=`/progress/${deal.id}/`}}>
        <div className="row">
            <div className="name">{deal.name}<div className="date">от {deal.date}</div></div>
            
            <div className="info">
                <div className="organization">{deal.object}</div>
                <div className="sub">{deal.project}</div>
                <div className="object">{deal.state}</div>
                
            </div>
            <div className="sum">
           
              {Object.keys(deal.total).length && Object.keys(deal.total).map((currency, index) => ( 
                <div key={index}>{currency}: {deal.total[currency]}</div>
              ))}
            
            </div>

        </div>
        </div>*/
  

  return (  
    <>
      <Helmet>
            <title>Договоры</title>
    </Helmet>
    <Menu/>
    <div id="page">
    <TopBar/>
    <div className="container">
      <div className="header">Договоры</div>
     
      
        <div className="block">
         

          <div className="table nh">
              <div className="table_header">
                <div className="table_cell" style={{width:'250px'}}>
                  Договор
                </div>
                <div className="table_cell" style={{width:'250px'}}>
                  Проект, Объект
                </div>
                <div className="table_cell">
                  Сметы
                </div>
                <div className="table_cell">
                  КС-2
                </div>
              
              </div>

          {deals.count>0 && deals.deals.map((deal) => ( 
    
            <div className="table_row" key={deal.id}>
                <div className="table_cell">
                  <Link to={`/deal/${deal.id}/`}>
                    <div className="link">{deal.name}</div>
                    <div className="small_info">{new Date(deal.date).toLocaleDateString()}</div>
                  </Link>
                  
                </div>
                <div className="table_cell">
                  <Link to={`/project/${deal.project_id}/`}>
                    <div className="link">{deal.project}</div>
                  </Link>
                  <Link to={`/object/${deal.object_id}/`}>
                    <div className="link">{deal.object}</div>
                  </Link>
                </div>

                <div className="table_cell">
                  {deal.estimates && deal.estimates.map((estimate) => (
                    <div><Link to={`/estimate/${estimate.id}/`}>{estimate.number}<div className="small_right">от {new Date(estimate.date).toLocaleDateString()}</div></Link></div>
                  ))}
                </div>
                <div className="table_cell">
                  {deal.acts && deal.acts.map((act) => (
                    <div><Link to={`/act/${act.id}/`}>{act.number}<div className="small_right">от {new Date(act.date).toLocaleDateString()}</div></Link></div>
                  ))}
                </div>
                
              </div>

          ))}
           </div>
        </div>
        
        {!isLoading && deals.page_count>1? 
        <ReactPaginate
        breakLabel="..."
        nextLabel="Вперед"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        forcePage={deals.page-1}
        pageCount={deals.page_count}
        previousLabel="Назад"
        renderOnZeroPageCount={null}
      /> :null}
     
    </div>
    </div>
    </>
  );

}

