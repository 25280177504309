import React, { useEffect, useState } from 'react';
import axiosInstance from '../../components/AxiosInstance';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import {config} from '../../config/config.js';
import { useAuth } from "../../components/AuthProvider";
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";
import header_progress_svg from '../../assets/img/header_progress.svg';
import filter_svg from '../../assets/img/filter.svg';
import arrow_down_svg from '../../assets/img/arrow_down.svg';
import edit_svg from '../../assets/img/edit.svg';
import save_svg from '../../assets/img/save.svg';
import warning_svg from '../../assets/img/warning.svg';
import { Helmet } from 'react-helmet';


export default function EstimatePage() {
  const { id } = useParams()
  const navigate = useNavigate();
  const [tableRow, setTableRow] = useState(0);
   const [estimate, setEstimate] = useState([]);
   const [isLoading, setisLoading]=useState(true);
   const [isError, setisError] = useState([]);
   const [isSuccess, setisSuccess] = useState([]);



  useEffect(() => {
    setisLoading(true);

    
     axiosInstance.get(`/base/admin/estimate/${id}`)
    .then(function(response){
      

      setEstimate(response.data)
      setTableRow(2+Object.keys(response.data['total']).length*5)
      setisLoading(false)
    })
    .catch((err) => {
      navigate('/404')
      setisError(true);
      setisLoading(false);
      });
    }, [])



  return (  
    <>
     {estimate.id?<Helmet>
            <title>Смета {estimate.number} от {new Date(estimate.date).toLocaleDateString()}</title>
    </Helmet>:null}
    <Menu/>
    <div id="page">
    <TopBar/>
    <div className="container">
      
      {estimate.number?
        <>
          <div className="header">Смета {estimate.number} от {new Date(estimate.date).toLocaleDateString()}</div>

          <div className="card_row">
            <div className="card">
                <div className="card_header">{estimate.number}</div>
                <div className="card_date">от {new Date(estimate.date).toLocaleDateString()}</div>
            </div>
            <div className="card pt">
              <div className="card_table">
                <div className="card_attr">Договор</div>
                <div className="card_value"><Link to={`/admin/deal/${estimate.deal_id}/`}>{estimate.deal_name}</Link></div>
              </div>
              <div className="card_table">
                <div className="card_attr">Проект</div>
                <div className="card_value"><Link to={`/admin/project/${estimate.project_id}/`}>{estimate.project_name}</Link></div>
              </div>
              <div className="card_table">
                <div className="card_attr">Объект</div>
                <div className="card_value"><Link to={`/admin/object/${estimate.object_id}/`}>{estimate.object_name}</Link></div>
              </div>
              <div className="card_table">
                <div className="card_attr">Налоговая ставка</div>
                <div className="card_value">{estimate.tax_value}</div>
              </div>
              <div className="card_table">
                <div className="card_attr">Цена включает НДС</div>
                <div className="card_value">{estimate.tax?'Да':'Нет'}</div>
              </div>
              
            
            </div>
            <div className="card">
            
                <Link to={`/admin/sum/${estimate.id}/`} style={{marginTop:'20px','display':'inline-block'}}>Перейти в накопительную по смете</Link>
                  
            </div>
          </div>

          <div className="block">
           <div className="block_table">

            <table className="list" id="table" estimate_id={estimate.id}>
            <thead>
              <tr>
                 
                <th>Наименование работ</th>
                <th>ID</th>

                <th>Кол-во</th>
                <th>Ед.изм.</th>
                
                {estimate.total && Object.keys(estimate.total).map((currency) => (  
                  <>
                  <th>Цена материала за ед., {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость материала, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Цена работ за ед., {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость работ, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                    <th>Стоимость Итого, {currency!=='null'?currency:'Валюта'}{!estimate.tax?', без НДС':null}</th>
                  </>
                ))}
              </tr>

            
               </thead>       
               <tbody>

                {estimate.works && estimate.works.map((work) => (  
                  <>
                    
                     {work.section ?
              <tr className="section show" level={work.level} key={work.number}>
                <td>{work.name}</td>
                <td>{work.number}</td>
                <td></td>
                <td></td>
                {work.money && Object.keys(estimate.total).map((currency) => ( 
                <>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                <td className="right"></td>
                </>
              ))}
                


               
                
          
              </tr>
             :
              <tr className="show"  work_id={work.work_estimate} level={work.level} key={work.number}>
                <td>{work.name}</td>
                <td><span>{work.number}</span></td>
                
         
                <td className="center">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.workload)}</td>
                <td className="center">{work.unit}</td>
                  {Object.keys(estimate.total).map((currency) => (  
            <>
            
              {work.money[currency] ?
                <>  
                  {work.money[currency].cost_materials_per_unit ?
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_materials_per_unit)}</td>
                  : 
                   <td className="right"></td>}

                   {work.money[currency].cost_materials ?
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_materials)}</td>
                  : 
                   <td className="right"></td>}

                   {work.money[currency].cost_installation_per_unit ?
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_installation_per_unit)}</td>
                  : 
                   <td className="right"></td>}

                   {work.money[currency].cost_installation ?
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_installation)}</td>
                  : 
                   <td className="right"></td>}

                  {work.money[currency].cost_total ?
                   <td className="right">{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(work.money[currency].cost_total)}</td>
                  : 
                   <td className="right"></td>}
                  
                </>
              :
                <><td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td>
                  <td className="right"></td></>
              
              
              
              }
                

                    
                  </>
                ))}
              </tr>
              }

              
              </>
              ))}
              <tr id="total" className="show" >
                  <td className="right" colSpan="4"><b>Всего по смете,  без НДС:</b></td>
                  
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>{estimate.tax?Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((estimate.money[currency].cost_total*(100-parseInt(estimate.tax_value))/100).toFixed(2)):Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(estimate.money[currency].cost_total)}</b></td>
                    </>
                  ))}
                </tr>
                {estimate.tax_value!='Без НДС'?
                <tr id="total_tax" className="show" >
                  <td className="right" colSpan="4"><b>НДС {estimate.tax_value}:</b></td>
                  
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>{Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((estimate.money[currency].cost_total*parseInt(estimate.tax_value)/100).toFixed(2))}</b></td>
                    </>
                  ))}
                </tr>
                :null}
                {estimate.tax_value!='Без НДС'?
                <tr id="total_full" className="show" >
                  <td className="right" colSpan="4"><b>Всего по смете,  с НДС {estimate.tax_value}:</b></td>
                  
                  {Object.keys(estimate.money).map((currency) => ( 
                    <>              
                     <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="right"><b>{estimate.tax?Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format(estimate.money[currency].cost_total):Intl.NumberFormat("ru", {minimumFractionDigits: 2}).format((estimate.money[currency].cost_total*(parseInt(estimate.tax_value)+100)/100).toFixed(2))}</b></td>
                    </>
                  ))}
                </tr>
                :null}
                
                </tbody>
             
            </table>


          </div>

        </div>
        </>
        :
        null
        }
        
      </div>

    </div>
    </>
  );

}

