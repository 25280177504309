import React, {useState} from "react";
import { useAuth } from "./AuthProvider";
import {Link} from "react-router-dom";
import axios from 'axios'
import axiosInstance from '../components/AxiosInstance';
import exit_svg from '../assets/img/exit.svg';


function TopBar() {
  const { user } = useAuth();
  const [administrators, setAdministrators] = useState([]);
  // const [administrators, setAdministrators] = useState([]);
  /*
   function handleExit(e) {
    e.preventDefault();

    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('email');
    localStorage.removeItem('keycloak');
    localStorage.removeItem('superadmin');
    localStorage.removeItem('admin_name');
    window.location.href = '/login/';


  }*/

/*
  function handleExit(e) {
    e.preventDefault();

    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    localStorage.removeItem('email');
    localStorage.removeItem('keycloak');
    localStorage.removeItem('superadmin');
    localStorage.removeItem('admin_name');
    window.location.href = '/login/';

  }
  */
  function handleExit(e) {
    e.preventDefault();
    if(user.keycloak) {
        // window.location.href = config.keycloak.server_url+'/realms/'+config.keycloak.realm_name+'/protocol/openid-connect/logout'
       
    
        axios.post('/api/keycloak/logout/', {'refresh': localStorage.getItem('refresh')})
            .then(function (response) {
                if(response.status === 200){
                   localStorage.removeItem('access');
                    localStorage.removeItem('refresh');
                    localStorage.removeItem('email');
                    localStorage.removeItem('sub_name');
                    localStorage.removeItem('keycloak');
                    localStorage.removeItem('superadmin');
                    localStorage.removeItem('admin_name');
                    window.location.href = '/login/';
                }
            })
    } else {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        localStorage.removeItem('email');
        localStorage.removeItem('sub_name');
        localStorage.removeItem('keycloak');
        localStorage.removeItem('superadmin');
        localStorage.removeItem('admin_name');
        window.location.href = '/login/';
    }
    
}
function handleAdmin(e) {
    if(e.target.innerText==='Суперадмин') {
        localStorage.removeItem('admin_name');
        document.getElementById('admin_select').innerText='Суперадмин'
    } else {
        localStorage.setItem('admin_name', e.target.innerText);
        document.getElementById('admin_select').innerText=e.target.innerText
    }
    window.location.reload();
    
}
 function handleAdmins(e) {
 

    
    
     axiosInstance.get(`/base/admins/`)
    .then(function(response){
        
        setAdministrators(response.data)
        // let admin_block=document.querySelector('#superadmin ul');
        // admin_block.innerHtml=''
        // response.data.administrators.forEach(administrator => {
        //     // admin_block.appendChild('<li>'+administrator['name']+'</li>');
        //     console.log(administrator.name)
        //     // console.log(administrator)
        //     // ...use `element`...
        // });

        // admin_block.appendChild(textnode);
      // console.log(admin_block)
      // setAdministrators(response.data.administrators)
        // console.log(response.data.administrators.length)


        
    })
    .catch((err) => {

      });
}
  return (
    <div id="topbar">
       
        <div id="user">
            {user.email} {user.sub_name?user.sub_name:<>{!user.superadmin?'Администратор':null}</>}  
            <ul>
                <li><Link to="/profile/">Профиль</Link></li>
                <li><Link onClick={handleExit}>Выйти</Link></li>
            </ul>
        </div>
        {user.superadmin?
        <div id="superadmin" onMouseEnter={handleAdmins}>
            Просмотр как: <span id="admin_select">{user.admin_name?user.admin_name:'Суперадмин'}</span>
            <ul>
                <li value="superadmin" onClick={handleAdmin}>Суперадмин</li>
                {administrators.administrators && administrators.administrators.map((admin, i) => (
                <li onClick={handleAdmin} key={i}>{admin.name}</li>
              ))}
                
            </ul>
        </div>
        :null}
        <div id="exit" onClick={handleExit}><img src={exit_svg} alt=""/></div>
    </div>
  );
}

export default TopBar;