import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import {AuthProvider,RequireAuth} from "./components/AuthProvider";


import LoginPage from "./pages/Login";
import ApprovePage from "./pages/Login/Approve";
import ResetPage from "./pages/Reset";
import KeycloakPage from "./pages/Keycloak";

import SubsPage from "./pages/Sub/Subs";
import SubPage from "./pages/Sub/Sub";

import ActsPage from "./pages/Act/Acts";
import ActPage from "./pages/Act/Act";
import ActsAdminPage from "./pages/Act/ActsAdmin";
import ActAdminPage from "./pages/Act/ActAdmin";

import EstimatesPage from "./pages/Estimate/Estimates";
import EstimatePage from "./pages/Estimate/Estimate";
import EstimatesAdminPage from "./pages/Estimate/EstimatesAdmin";
import EstimateAdminPage from "./pages/Estimate/EstimateAdmin";

import SumsAdminPage from "./pages/Sum/SumsAdmin";
import SumAdminPage from "./pages/Sum/SumAdmin";

import ReportsPage from "./pages/Report/Reports";
import ReportPage from "./pages/Report/Report";
import ReportsAdminPage from "./pages/Report/ReportsAdmin";
import ReportAdminPage from "./pages/Report/ReportAdmin";

import DealsPage from "./pages/Deal/Deals";
import DealPage from "./pages/Deal/Deal";
import DealsAdminPage from "./pages/Deal/DealsAdmin";
import DealAdminPage from "./pages/Deal/DealAdmin";


import ProjectsPage from "./pages/Project/Projects";
import ProjectPage from "./pages/Project/Project";
import ProjectsAdminPage from "./pages/Project/ProjectsAdmin";
import ProjectAdminPage from "./pages/Project/ProjectAdmin";

import ProfilesAdminPage from "./pages/Profile/ProfilesAdmin";

import ObjectsPage from "./pages/Object/Objects";
import ObjectPage from "./pages/Object/Object";
import ObjectsAdminPage from "./pages/Object/ObjectsAdmin";
import ObjectAdminPage from "./pages/Object/ObjectAdmin";


import MainPage from "./pages/Main";
import ProfilePage from "./pages/Profile/Profile";

import PageNotFound from "./pages/PageNotFound";

export const API_URL = "http://0.0.0.0:8000/"

// const App = () => {
//     // const navigate = useNavigate()
//     const accessToken = useState('')

//     // useEffect(() => {
//     //     navigate('/login', { replace: true })
//     // })


//     // const accessToken = useAppSelector((state) => state.authSlice.accessToken)

//     // useEffect(() => {
//     //     if (accessToken === '') {
//     //         navigate('/login', { replace: true })
//     //     } else {
//     //         navigate('/', { replace: true })
//     //     }
//     // }, [accessToken])

//     return (
//         <>
//             {accessToken !== '' ? (
//                 <Routes>
//                     <Route path="/progress/" element={<ProgressPage />} />
//                     <Route path="/progress/:id/" element={<ProgressOnePage />} />
//                     <Route path='*' element={<Navigate replace to="/404" />}/>
//                 </Routes>
//             ) : (
//                 <Routes>
//                     <Route path='/login' element={<LoginPage />} />
//                     <Route path='/reset' element={<ResetPage />} />
//                     <Route path='*' element={<Navigate replace to="/404" />}/>
//                 </Routes>
//             )}

//         </>
//     )
// }

// export default App

// export default class App extends React.Component {

// const navigate = useNavigate()
// const accessToken = useState('')

//     useEffect(() => {
//         navigate('/login')
//     })
//   render() {
//     return (
//       <>
//             {accessToken !== '' ? (
//                 <Routes>
//                     <Route path="/progress/" element={<ProgressPage />} />
//                     <Route path="/progress/:id/" element={<ProgressOnePage />} />
//                     <Route path='*' element={<Navigate replace to="/404" />}/>
//                 </Routes>
//             ) : (
//                 <Routes>
//                     <Route path='/login' element={<LoginPage />} />
//                     <Route path='/reset' element={<ResetPage />} />
//                     <Route path='*' element={<Navigate replace to="/404" />}/>
//                 </Routes>
//             )}

//         </>
//     );
//   }
// }.


// class App extends Component {


//   render() {
//     return (
//       <div className="main__wrap">
//         asdasd
//       </div>
//     );
//   }
// }

// export default App;

export default function App() {


		// console.log(config['access_token']);
		
	  
		// navigate('/login', { replace: true })

	  return (
	  	<AuthProvider>
		 <BrowserRouter>
		 
			
			<>
			
				
				<Routes>
					
					<Route element={<RequireAuth />}>
						<Route path="/reset/*/" element={<ResetPage />} />
						<Route path="/profile/" element={<ProfilePage />} />

						<Route path="/acts/" element={<ActsPage />} />
						<Route path="/act/:id/" element={<ActPage />} />
						<Route path="/estimates/" element={<EstimatesPage />} />
						<Route path="/estimate/:id/" element={<EstimatePage />} />
						<Route path="/deals/" element={<DealsPage />} />
						<Route path="/deal/:id/" element={<DealPage />} />
						<Route path="/projects/" element={<ProjectsPage />} />
						<Route path="/project/:id/" element={<ProjectPage />} />
						<Route path="/objects/" element={<ObjectsPage />} />
						<Route path="/object/:id/" element={<ObjectPage />} />
						<Route path="/reports/" element={<ReportsPage />} />
						<Route path="/report/:id/" element={<ReportPage />} />
						
						<Route path="/admin/acts/" element={<ActsAdminPage />} />
						<Route path="/admin/act/:id/" element={<ActAdminPage />} />
						<Route path="/admin/estimates/" element={<EstimatesAdminPage />} />
						<Route path="/admin/estimate/:id/" element={<EstimateAdminPage />} />
						<Route path="/admin/sum/" element={<SumsAdminPage />} />
						<Route path="/admin/sum/:id/" element={<SumAdminPage />} />
						<Route path="/admin/deals/" element={<DealsAdminPage />} />
						<Route path="/admin/deal/:id/" element={<DealAdminPage />} />
						<Route path="/admin/projects/" element={<ProjectsAdminPage />} />
						<Route path="/admin/project/:id/" element={<ProjectAdminPage />} />
						<Route path="/admin/objects/" element={<ObjectsAdminPage />} />
						<Route path="/admin/object/:id/" element={<ObjectAdminPage />} />
						<Route path="/admin/reports/" element={<ReportsAdminPage />} />
						<Route path="/admin/report/:id/" element={<ReportAdminPage />} />

						<Route path="/admin/profiles/" element={<ProfilesAdminPage />} />


						<Route path="/admin/subs/" element={<SubsPage />} />
						<Route path="/admin/sub/:id/" element={<SubPage />} />

						<Route path='/404' element={<PageNotFound />}/>
						<Route path='/' element={<MainPage />}/>
					</Route>
					<Route path='/login/' element={<LoginPage />}/>
					<Route path='/approve/:code' element={<ApprovePage />}/>
					<Route path='/keycloak/' element={<KeycloakPage />}/>
					<Route path='/reset/' element={<ResetPage />}/>
					<Route path='*' element={<Navigate replace to="/404" />}/>
				</Routes>
				
			</>
		
		
	  
		  
		 
		</BrowserRouter>
		</AuthProvider>
	  );
}

/*
):(
			<Routes>
				<Route path="/login/" element={<LoginPage />} />
				<Route path="/reset/" element={<ResetPage />} />
				<Route path='*' element={<Navigate replace to="/login/" />}/>
			</Routes>
		)}
*/
