import React from "react";
import {Link,NavLink} from "react-router-dom";
import { useAuth } from "./AuthProvider";
import logo_svg from '../assets/img/logo.svg';
import progress_svg from '../assets/img/progress.svg';
import subs_svg from '../assets/img/subs.svg';
import act_svg from '../assets/img/act.svg';
import estimate_svg from '../assets/img/estimate.svg';
import sum_svg from '../assets/img/sum.svg';

import deal_svg from '../assets/img/deal.svg';
import project_svg from '../assets/img/project.svg';
import object_svg from '../assets/img/object.svg';
import report_svg from '../assets/img/report.svg';


function Menu() {
  const { user } = useAuth();
 

  return (
     <div id="left">
  
       <div id="logo"><Link to="#"><img src={logo_svg} alt="logo"/></Link></div>
            <ul id="menu">
                    {user.keycloak?
                        <>
                            
                            <li><NavLink to="/admin/subs/" className={({ isActive }) => isActive ? 'active' : undefined}><img src={subs_svg} alt=""/><div>Субподрядчики</div></NavLink></li>
                            <li><NavLink to="/admin/deals/" className={({ isActive }) => isActive ? 'active' : undefined}><img src={deal_svg} alt=""/><div>Договоры</div></NavLink></li>
                            <li><NavLink to="/admin/sum/" className={({ isActive }) => isActive ? 'active' : undefined}><img src={sum_svg} alt=""/><div>Накопительные</div></NavLink></li>
                            <li><NavLink to="/admin/estimates/" className={({ isActive }) => isActive ? 'active' : undefined}><img src={estimate_svg} alt=""/><div>Сметы</div></NavLink></li>
                            <li><NavLink to="/admin/acts/" className={({ isActive }) => isActive ? 'active' : undefined}><img src={act_svg} alt=""/><div>КС-2</div></NavLink></li>
                            <li><NavLink to="/admin/reports/" className={({ isActive }) => isActive ? 'active' : undefined}><img src={report_svg} alt=""/><div>Выполнение</div></NavLink></li>
                            <li><NavLink to="/admin/projects/" className={({ isActive }) => isActive ? 'active' : undefined} style={{display:'none'}}><img src={project_svg} alt=""/><div>Проекты</div></NavLink></li>
                            <li><NavLink to="/admin/objects/" className={({ isActive }) => isActive ? 'active' : undefined} style={{display:'none'}}><img src={object_svg} alt=""/><div>Объекты</div></NavLink></li>
                        </>
                    :
                        <>
                            <li style={{'display': 'none'}}><NavLink to="/progress/" className={({ isActive }) => isActive ? 'active' : undefined}><div>Накопительные</div><img src={progress_svg} alt=""/></NavLink></li>
                            <li><NavLink to="/deals/" className={({ isActive }) => isActive ? 'active' : undefined} style={{display:'none'}}><img src={deal_svg} alt=""/><div>Договоры</div></NavLink></li>
                            <li><NavLink to="/estimates/" className={({ isActive }) => isActive ? 'active' : undefined}><img src={sum_svg} alt=""/><div>Накопительные</div></NavLink></li>
                            <li><NavLink to="/acts/" className={({ isActive }) => isActive ? 'active' : undefined}><img src={act_svg} alt=""/><div>КС-2</div></NavLink></li>
                            <li><NavLink to="/reports/" className={({ isActive }) => isActive ? 'active' : undefined}><img src={report_svg} alt=""/><div>Выполнение</div></NavLink></li>
                            <li><NavLink to="/projects/" className={({ isActive }) => isActive ? 'active' : undefined} style={{display:'none'}}><img src={project_svg} alt=""/><div>Проекты</div></NavLink></li>
                            <li><NavLink to="/objects/" className={({ isActive }) => isActive ? 'active' : undefined} style={{display:'none'}}><img src={object_svg} alt=""/><div>Объекты</div></NavLink></li>
                        </>
                    }


                    
                    
                
                
            </ul>
    </div>
  );
}

export default Menu;