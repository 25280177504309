import React, { useEffect, useState } from 'react';
import axiosInstance from '../../components/AxiosInstance';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useAuth } from "../../components/AuthProvider";
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";




export default function ObjectPage() {
   const { id } = useParams()


   const [deals, setDeals] = useState([]);
   const [isLoading, setisLoading]=useState(true);
   const [isError, setisError] = useState(false);

  useEffect(() => {
    setisLoading(true);
// console.log('token', axios.defaults.headers.common["Authorization"])
   axiosInstance.get(`/base/object/${id}`)
    .then(function(response){
      
      setDeals(response.data)
      setisLoading(false)
    })
    .catch((err) => {
      if(err.response.status===403) {
        // setToken(false)
      }
        setisError(true);
        setisLoading(false);
      });
  }, [])
 

   





  return (  
     <>
    {deals && deals.object?<Helmet><title>Объект {deals.object}</title></Helmet>:null}
     <Menu/>
    <div id="page">
    <TopBar/>
    <div className="container">
      <div className="header">Объект {deals.object?deals.object:null}</div>
      <div id="progress">
      
        <div className="block">
         

          <div className="table nh">
              <div className="table_header">
                <div className="table_cell">
                  Договор
                </div>
                <div className="table_cell">
                  Проект, Объект
                </div>
                <div className="table_cell">
                  Сметы
                </div>
                <div className="table_cell">
                  КС-2
                </div>
           
              </div>

          {deals.count>0 && deals.deals.map((deal) => ( 
    
            <div className="table_row" key={deal.id}>
                <div className="table_cell">
                  <Link to={`/deal/${deal.id}/`}>
                    <div class="link">{deal.name}</div>
                    <div class="small_info">{new Date(deal.date).toLocaleDateString()}</div>
                  </Link>
                  
                </div>
                <div className="table_cell">
                  <Link to={`/project/${deal.project_id}/`}>
                    <div class="link">{deal.project}</div>
                  </Link>
                  <Link to={`/object/${deal.object_id}/`}>
                    <div class="link">{deal.object}</div>
                  </Link>
                </div>

                <div className="table_cell">
                  {deal.estimates && deal.estimates.map((estimate) => (
                    <div><Link to={`/estimate/${estimate.id}/`}>{estimate.number}<div class="small_right"> от {new Date(estimate.date).toLocaleDateString()}</div></Link></div>
                  ))}
                </div>
                <div className="table_cell">
                  {deal.acts && deal.acts.map((act) => (
                    <div><Link to={`/act/${act.id}/`}>{act.number}<div class="small_right"> от {new Date(act.date).toLocaleDateString()}</div></Link></div>
                  ))}
                </div>
               
              </div>

          ))}
           </div>
        </div>
    
      </div>
    </div>
    </div>
    </>
  );


}

