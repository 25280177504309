import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import logo_svg from '../../assets/img/logo.svg';

export default function ResetPage() {
  return (
    <>
       <div className="login_block">
        <div className="login_header"><img src={logo_svg}/>Восстановление пароля</div>
        <div className="text">Инструкция по восстановлению пароля отправлена на ваш Email</div>
        <div className="text">Вернуться на <Link to="/login/">страницу входа</Link></div>
      </div>

    </>
  );
}