import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axiosInstance from '../../components/AxiosInstance';
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";

export default function ProfilePage() {



   const [permissions, setPermissions] = useState([]);
   const [isLoading, setisLoading]=useState(true);
   const [isError, setisError] = useState(false);

  useEffect(() => {
    setisLoading(true);
// console.log('token', axios.defaults.headers.common["Authorization"])
   axiosInstance.get("/administrator/permission/")
    .then(function(response){
      


      setPermissions(response.data)
      setisLoading(false)
    })
    .catch((err) => {
      if(err.response.status===403) {
        // setToken(false)
      }
        setisError(true);
        setisLoading(false);
      });
  }, [])


  return (
    <>
      <Menu/>
      <div id="page">
      <TopBar/>
      <div className="container">
        <div className="header">Профиль</div>
        

           <div className="block">
          
           <div className="block_header">Доступы к договорам</div>
          <div className="table">
              <div className="table_header">
                <div className="table_cell">
                  Объект
                </div>
                <div className="table_cell">
                  Договор
                </div>
                <div className="table_cell">
                  ДС
                </div>
                 <div className="table_cell">
                  Субподрядчик
                </div>
                <div className="table_cell">
                  Полный доступ
                </div>
                 <div className="table_cell">
                  Срок
                </div>
              </div>
              {isLoading? 
                <>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div></div>
                 </>
              :
                <>
                {permissions.count>0?
                <>
                {permissions.permissions=='all'?
                  <div className='table_row'>
                    <div className="table_cell">
                       Все
                    </div>
                    <div className="table_cell">
                       Все
                    </div>
                    <div className="table_cell">
                       Все
                    </div>
                    <div className="table_cell">
                       Все
                    </div>
                    <div className="table_cell">
                       Да
                    </div>
                    <div className="table_cell">
                       Бессрочно
                    </div>
                    </div>:
                  <>
                  {permissions.permissions.map((permission) => (
      
                <div className={'table_row '+ (permission.expired?'expired':null)} key={permission.id}>
                    <div className="table_cell" report_id={permission.id}>
                        <Link to={`/admin/object/${permission.object_id}/`}>
                          <div class="link">{permission.object_name}</div>
                        </Link>
                    </div>
                    <div className="table_cell">
                      
                      <Link to={`/admin/deal/${permission.deal_id}/`}>
                        <div class="link">{permission.deal_name}</div>
                      </Link>
                    </div>
                    <div className="table_cell">
                      {permission.agreement_name}

                    </div>
                    <div className="table_cell">
              
                      <Link to={`/admin/sub/${permission.sub_id}/`}>
                        <div class="link">{permission.sub_name}</div>
                        <div class="small_info">{permission.sub_inn}</div>
                      </Link>
                    </div>
                    <div className="table_cell">
                      {permission.full_access?'Да':null}
                    </div>
                    <div className="table_cell">
                      {permission.validity}
                    </div>

                  </div>

              ))}
                  </>
                }
                
                </>
                  :
                  <div className="table_row"><div className="table_cell">Нет активных доступов</div></div>
                }
                </>
              }
           </div>
        </div>
        </div>
      </div>
    </>
  );
}