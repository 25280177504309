import React, {useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import logo_svg from '../../assets/img/logo.svg';
import pass_svg from '../../assets/img/pass.svg';
import show_svg from '../../assets/img/show.svg';
import hide_svg from '../../assets/img/hide.svg';

import axios from 'axios'



export default function ApprovePage() {
	const [error, setError]=useState('');
	const [next, setNext]=useState(false);
	const [done, setDone]=useState(false);
	const [pageLoad, setPageLoad]=useState(false);
	const [showPass , setShowPass] = useState(false)
	const [isLoading, setisLoading]=useState(true);

  	const { code } = useParams()
	useEffect(() => {

		if(!pageLoad) {
			setPageLoad(true)
			axios.get(`/api/user/approve/${code}`)
	            .then(function (response) {
	                if(response.status === 200){
	                   setNext(true)
	                   setisLoading(false)
	                }
	            })
	            .catch(function (error) {
	              	setisLoading(false)
	              	
	                setError(error.response.data.error)

	            });
		}
	            

	       

	        
	    })

	const handleShowPass = (e) => {
	    setShowPass(true)
	 }
	 const handleHidePass = (e) => {
	    setShowPass(false)
	 }

	const handleSubmit = (e) => {
    	e.preventDefault();
    	let pass=document.getElementById('pass').value

    	if(pass.length<6) {
    		setError('Пароль не может быть меньше 6 символов')
    	} else {
    		setError('')
    		setisLoading(true)
    		setNext(false)
    		axios.post(`/api/user/approve/${code}`, {"password": pass})
	            .then(function (response) {
	                if(response.status === 200){
	                   setDone(true)
	                   setisLoading(false)
	                }
	            })
	            .catch(function (error) {
	            	setNext(true)
	              	setisLoading(false)
	                setError(error.response.data.error)

	            });
    	}
	}
  return (
   
      <div className="login_block">
      	<div className="login_header"><img src={logo_svg} alt=""/>Регистрация</div>
      	 {isLoading?<div className="loader"><div className="loader_row"><div></div><div></div><div></div><div></div></div></div>:
            <>
       		
            {done?
            <>
            	<div className="text">
                    Ваш пароль успешно установлен. Вы можете <Link to='/login/'>войти на сайт</Link>
            </div>
            </>:<div className="error" style={{display: error ? 'block' : 'none' }}>
                    {error}
            </div>}
            {next?
            <>
               	<div className="text">Установите пароль для доступа к порталу, длина пароля должна быть не меньше 6 символов</div>
                <div className="value"><img src={pass_svg} className="icon" alt=""/><img src={show_svg} className={showPass?"pass pass_show":"pass active pass_show"} onClick={handleShowPass} alt=""/><img src={hide_svg} className={showPass?"pass active pass_show":"pass pass_show"} onClick={handleHidePass} alt=""/><input type={showPass?'text':'password'} id="pass" placeholder="Введите пароль" /></div> 
                <div className="submit"><input type="submit" value="Сохранить" onClick={handleSubmit}/></div>
               
            </>
            :null
        	}
        	</>}
             
      
        
        
        
      </div>
    
  );
}