import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axiosInstance from '../../components/AxiosInstance';
import {config} from '../../config/config.js';
import { useAuth } from "../../components/AuthProvider";
import Menu from "../../components/Menu";
import TopBar from "../../components/TopBar";
import header_progress_svg from '../../assets/img/header_progress.svg';
import filter_svg from '../../assets/img/filter.svg';
import arrow_down_svg from '../../assets/img/arrow_down.svg';
// axios.get("/base/deals/",
//   {headers: {'Authorization': 'Token eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwiZXhwIjoxNzE2ODc0OTg0fQ.PybPZuY9Cf2Rn6leZHDfsI5Mt3N93qt3gqnsuUSmAZM'}})
// .then((res) => console.log(res.data))
//       .catch((err) => console.log(err));

// getEvents(){
//         axios
//             .get("http://127.0.0.1:8000/api/getevents")
//             .then(response =>{
//                 this.state2.events = response.data;
//             }) 
//             .catch(err => console.log(err));
//     };





export default function ProjectsPage() {

      const navigate = useNavigate();

   const [projects, setProjects] = useState([]);
   const [isLoading, setisLoading]=useState(true);
   const [isError, setisError] = useState(false);

  useEffect(() => {
    setisLoading(true);
// console.log('token', axios.defaults.headers.common["Authorization"])
   axiosInstance.get("/base/admin/projects/")
    .then(function(response){
      


      setProjects(response.data)
      setisLoading(false)
    })
    .catch((err) => {
      if(err.response.status===403) {
        // setToken(false)
      }
        setisError(true);
        setisLoading(false);
      });
  }, [])



  return (  
   <>
    <Helmet>
            <title>Проекты</title>
    </Helmet>
    <Menu/>
    <div id="page">
    <TopBar/>
    <div className="container">
      <div className="header">Проекты</div>
      <div id="progress">
      
        <div className="block">
          

          <div className="table nh">
              <div className="table_header">
                <div className="table_cell">
                  Название
                </div>
                <div className="table_cell">
                  Количество договоров
                </div>
                 
              </div>
              {isLoading? 
                <>
                <div className="table_row"><div className="table_cell"><div className="text_placeholder"></div></div></div>
                 </>
              :
                <>
                {projects.count>0?
                <>
                {projects.projects.map((project) => (
      
                <div className="table_row" key={project.id}>
                    <div className="table_cell" report_id={project.id}>
                         <Link to={`/admin/project/${project.id}/`}>
                          <div class="link">{project.name}</div>
                         
                        </Link>
                      </div>
                    <div className="table_cell">
                      {project.deals}
                    </div>
                     

                  </div>

              ))}
                </>
                  :
                  <div className="table_row"><div className="table_cell">Проекты отсутствуют</div></div>
                }
                </>
              }
           </div>
        </div>
    
      </div>
    </div>
    </div>
    </>
  );

}

